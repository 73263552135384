@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;800&family=Poppins:wght@200;300;500;600;700&family=Raleway&display=swap');

body{
    margin: 0;
    font-family: 'Poppins', 'sans-serif';
    background: var(--bg);
    transition: all 3ms ease;
    color: var(--black);
    scroll-behavior: smooth;
    overflow-x: hidden;

}